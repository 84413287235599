<template>
  <div v-show="!wx" class="t_header">
    <div class="header">
      <img class="go_back" src="@/static/images/my_index/back_black.png" alt @click="go_back()">
      <h2 class="title">{{ msg }}</h2>
      <p v-show="address_management" class="address_management" @click="go_address_management()">地址管理</p>
      <div v-show="isShowRightMenu">
        <p v-show="address_management1" class="address_management" @click="go_add_address()">添加地址</p>
        <p v-show="address_management2" class="address_management" @click="go_add_addressJd()">添加地址</p>
      </div>
      <p v-show="coinBtn" class="coinBtn" @click="goCoins">{{ coinBtnTitle }}</p>
      <img v-show="show_search" class="img_search" src="@/static/images/my_index/search.png" alt @click="doSearch">
      <img v-show="!wx&&more&&isNotApp&&!isZKB&&!deleteHistory" class="mores" src="@/static/images/my_index/mores.png" alt @click="clickMore">
      <img v-show="deleteHistory" class="recycle_bin" src="https://newoss.zhulong.com/forum/202404/28/8/180208g8ky71kn2prqaiht.png" alt @click="goDelHistory">
      <span v-show="feedback&&!more" class="fankui" @click="fanKuiFunc"><img src="https://newoss.zhulong.com/forum/202408/16/25/150225isoy0ol21jqfikso.png" alt=""></span>
      <span v-show="!more&&chatmenu" class="chat_more" @click="showChat"><img src="@/static/images/my_index/mores.png" alt=""></span>
      <moreMenus v-show="showMoreMenu && !isXiaoChengxu"></moreMenus>
    </div>
  </div>
</template>

<script>
import tool from '@/utils/tools.js'
import moreMenus from '@/compontens/menu.vue'
// import isWx from '@/utils/isWechat.js'
import Cookie from 'js-cookie'
import { getAppCommonStatis } from '@/api/hometop.js'
import WechatState from '@/utils/isWechatState.js'
export default {
  components: {
    moreMenus
  },
  /* eslint-disable */
  props: ['msg', 'states', 'isShowRightMenu', 'more', 'feedback','show_search','address_management', 'address_management1', 'address_management2', 'coinBtn', 'coinBtnTitle', 'backs','deleteHistory','chatmenu'],
  data() {
    return {
      showMoreMenu: false,
      isNotApp: true,
      isZKB: false,
      // wx: isWx
      wx: false,
      isXiaoChengxu:WechatState.isWechatMiniprogram
    }
  },
  mounted() {
    this.isWxApplet()
    if (tool.isInApp()) {
      this.isZKB = true
      this.isNotApp = false
    } else {
      this.isNotApp = true
    }
    if (!this.isZKB) {
      const host = window.location.host
      const agency_id = Cookie.get('agency_id')
      if (host.indexOf('zk468') !== -1 || agency_id === 11) {
        this.isZKB = true
      }
    }
    this.getAppCommonStatis()
  },
  methods: {
    // 去课程删除的页面
    goDelHistory(){
      this.$router.push({
        path:'/delHistory'
      })
    },
    // 判断是否在微信小程序中
    isWxApplet(){
      var ua = navigator.userAgent.toLowerCase()
      if (ua.match(/MicroMessenger/i) == 'micromessenger') {
        // 必须在WeixinJSBridge回调后进行判断，否则小程序环境检测会不准确
        if (!window.WeixinJSBridge || !WeixinJSBridge.invoke) {
          // document.addEventListener('WeixinJSBridgeReady', webchatReady, false)
        } else {
          this.ready()
        }
      } else {
        console.log('不在微信浏览器内')
      }
    },
    ready() {
      if (window.__wxjs_environment === 'miniprogram') {
        console.log('在小程序内')
        this.wx = true
      } else {
        console.log('不在小程序内')
        this.wx = false
      }
    },
    clickMore() {
      console.log('点击更多按钮了')
      this.showMoreMenu = !this.showMoreMenu
    },
    doSearch() {
      this.$emit('doSearch')
    },
    showChat(){
      this.$emit('chatList')
    },
    go_back() {
      if (tool.isInApp()) {
        if (tool.isInIosApp()) {
          window.webkit.messageHandlers.vue_backApp.postMessage('app')
        } else {
          this.$jsbridge.callHandler(
            'vue_backApp'
            , '返回'
            , function(responseData) {
              // android 返参数接受
            }
          )
        }
        return
      }
      // 修改地址带有order_id返回购买课程页面
      if(this.$route.name == 'edit'){
        this.$router.push({
          path:'/buylesson'
        })
      }else{
        this.$router.go(-1)
      }
      
    },
    // 反馈
    fanKuiFunc() {
      this.$router.push({path:'/feedBack',query:{gid:'856'}})
    },
    go_address_management() {
      // 快递》》》》地址管理
      this.$router.push({ path: '/index/express/address_management', query: { backs: 1 }})
    },
    go_add_address() {
      // 快递》》》》地址管理>>>>>添加地址
      if (this.can_click_right_menu){
        this.$router.push({ path: '/index/express/add_address' })
      }else {
        this.$toast("最多添加五个收货地址")
      }
    },
    go_add_addressJd() {
      this.$router.push({ path: '/addAddress' })
    },
    goCoins() {
      // 筑龙币记录
      this.$router.push({ path: '/coin', query: { name: this.coinBtnTitle }})
    },
    // 三方统计
    getAppCommonStatis() {
      getAppCommonStatis().then(res => {
        console.log(res, 'res')
        this.statis = res.result
        // var bd_script = document.createElement('script')
        // bd_script.type = 'text/javascript'
        // bd_script.innerHTML = this.statis.baidu_h5
        // // console.log(this.statis, 'this.statis')
        // document.head.appendChild(bd_script)
        var cc_script = document.createElement('script')
        cc_script.type = 'text/javascript'
        cc_script.innerHTML = this.statis.circle
        document.head.appendChild(cc_script)
        var cz_script = document.createElement('script')
        cz_script.type = 'text/javascript'
        cz_script.src = 'https://s4.cnzz.com/z_stat.php?id=5078536&web_id=5078536'
        // cz_script.innerHTML = this.statis.cnzz
        document.head.appendChild(cz_script)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.t_header {
  height: 120px;
}
.header {
  width: 100%;
  height: 120px;
  background-color: #ffffff;
  // position: relative;
  position: fixed;
  top: 0;
  z-index: 1000;
  border-bottom: 1px solid #eee;
}
.go_back {
  width: 68px;
  height: 68px;
  position: absolute;
  bottom: 30px;
  left: 10px;
  float: left;
}
.title {
  font-weight: 700;
  color: #333;
  text-align: center;
  margin: 0 auto;
  height: 120px;
  line-height: 120px;
}
.mores {
  width: 40px;
  height: 36px;
  position: absolute;
  right: 30px;
  top: 45px;
  z-index: 1;
}
.fankui {
  position: absolute;
  width: 100px;
  height: 80px;
  right: 20px;
  top: 24px;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #999;
  font-weight: 500;
  img {
    display: block;
    width: 100px;
  }
}
.recycle_bin{
  width: 50px;
  height: 46px;
  position: absolute;
  right: 30px;
  top: 38px;
  z-index: 1;
}

.img_search {
  width: 42px;
  height: 42px;
  position: absolute;
  right: 26px;
  top: 40px;
  z-index: 1;
}
.address_management {
  height: 120px;
  line-height: 120px;
  font-size: 25px;
  position: absolute;
  right: 32px;
  bottom: 0px;
}
.coinBtn {
  height: 120px;
  line-height: 120px;
  position: absolute;
  right: 32px;
  bottom: 0px;
  font-family: PingFangSC-Regular;
  font-size: 30px;
  color: #333333;
  text-align: center;
}
.chat_more {
  position: absolute;
  width: 40px;
  height: 40px;
  right: 14px;
  top: 32px;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 40px;
    height: 36px;
    display: block;
  }
}
</style>

<template>
  <div class="main">
    <heads v-show="!isInWx" :more="false" :go_back="1" :msg="title"></heads>
    <div class="content" v-loading="loading">
      <img class="iv_banner" :src="pageInfo.banner" alt="">
      <div class="div_content">
        <el-card class="box-card1">
          <div class="code_login">
            <div class="phone">
              <div class="diqu">
                <span>+</span>
                <input v-model="guoji" type="text">
              </div>
              <input v-model="phone" style="background: #fff !important" class="phone_inp" type="text" placeholder="请输入手机号">
            </div>
            <div class="code_box">
              <div class="code_m">
                <input v-model="ucode" type="text" placeholder="请输入验证码">
              </div>
              <div class="get_code" @click="getcode()">
                {{ codeBtnText }}
              </div>
            </div>
          </div>
          <div class="signBtn" style="margin-bottom: 8px">
            <span class="signBtn1" style="padding:  0 12px;background: #ee2e2e" @click="register()">{{btnText}}</span>
          </div>
        </el-card>
        <el-card class="box-card1">
          <div class="div_yhj">
            <div class="div_left">
              <div class="div_box">
                <div class="div_1">新用户注册可以获得{{pageInfo.reg_score + pageInfo.scoreName}}</div>
                <div class="div_2">领取后永久有效</div>
                <div class="div_3">积分可以在礼品专区兑换精美礼品哦~</div>
              </div>
            </div>
            <div class="div_right">
              <div>{{pageInfo.reg_score}}</div>
              <div style="margin-top: 4px">{{pageInfo.scoreName}}</div>
            </div>
          </div>
          <img class="iv_gift" src="@/static/gift.png" alt="">
        </el-card>
      </div>

    </div>
  </div>
</template>

<script>
import { Toast } from 'vant';
import heads from '@/compontens/heads.vue'
import { getRepairSign,getRegScore} from '@/api/regInfo'
import WechatState from '@/utils/isWechatState.js'
import { loginByPhone, sendMobileCode } from '@/api/login.js'
import Cookies	from 'js-cookie'

export default {
  components: {
    heads
  },
  data() {
    return {
      title: '邀请注册',
      pageInfo:{reg_score:0,scoreName:'积分'},
      guoji:'86',
      codeBtnText: '获取验证码',
      codeCount: 59,
      loading:false,
      invite_uid:'',
      phone: '',
      ucode: '',
      timer: null,
      btnText:'立即注册，领积分换豪礼',
      isNewUser:false,
      isLogin:false,
      isInWx: false,

    }
  },
  mounted() {
    const wx = navigator.userAgent.toLowerCase()
    if (wx.match(/MicroMessenger/i) == 'micromessenger') {
      // 判断是否已经授权
      this.isInWx = true
    } else {
      this.isInWx = false
    }
    this.invite_uid = this.$route.query.invite_uid
    if (!WechatState.isWechat) {
      // 创建script标签，引入外部文件
      const script = document.createElement('script')
      script.type = 'text/javascript'
      script.src = '../../TCaptcha.js'
      document.getElementsByTagName('head')[0].appendChild(script)
    }
    this.getPageInfo();
  },
  methods: {
    down_() {
      this.timer = setInterval(this.timerAction, 1000)
    },
    getcode() {
      if (this.timer) return
      var reg = /^1[3|4|5|7|8|6|9][0-9]\d{8}$/
      if (this.guoji == '86' || this.guoji == '+86') {
        if (!reg.test(this.phone)) {
          Toast('手机号格式有误')
          return false
        }
      } else {
        if (!this.phone || this.phone.length <= 0) {
          Toast('请输入手机号')
          return false
        }
      }
      if (WechatState.isWechat) {
        // 直接发验证码
        this.sendYzmNoCheck()
      } else {
        // 滑块验证
        this.useTencentCheck()
      }
    },
    // 不验证滑块的 直接发验证码
    sendYzmNoCheck() {
      const phone = this.phone
      var params = {
        guoji:'+'+this.guoji,
        type: 1,
        mobile: phone,
        code_type: 1,
        client_id: 500
      }
      sendMobileCode(params).then((res) => {
        if (res.errNo === 0) {
          this.codeCount = 59
          this.down_()
          Toast('验证码发送成功')
        }
      })
    },
    // 验证
    useTencentCheck() {
      console.log('滑块验证')
      const appid = '2090316930' // 腾讯云控制台中对应这个项目的 appid
      // 生成一个滑块验证码对象
      const that = this
      const captcha = new TencentCaptcha(appid, function(res) {
        console.log(res)
        if (res.ret == 0) {
          console.log(111)
          // 成功，传递数据给后台进行验证
          that.randstr = res.randstr
          that.ticket = res.ticket
          that.sendYzm()
        } else {
          // 提示用户完成验证
        }
      })
      // 滑块显示
      captcha.show()
    },
    // 倒计时方法
    timerAction() {
      let time = this.codeCount
      if (time > 0) {
        const seconds = Math.floor(time % 60)
        const title = seconds + 's'
        time -= 1
        this.codeCount = time
        this.codeBtnText = title
      } else {
        this.closeTimer()
        this.codeCount = 59
        this.codeBtnText = '重新获取'
      }
    },
    // 关闭倒计时
    closeTimer() {
      clearInterval(this.timer)
      this.timer = null
    },
    sendYzm() {
      var params = {
        guoji:'+'+this.guoji,
        app_id: 1,
        mobile: this.phone,
        ticket: this.ticket,
        randstr: this.randstr,
        code_type: 1
      }
      sendMobileCode(params).then(res => {
        if (res.errNo === 0) {
          this.codeCount = 59
          this.down_()
          Toast('验证码发送成功')
        }
      })
    },
    register(){
      if (this.isLogin){
        this.$router.push({path: '/gift'})
      }else{
        if (!this.phone) {
          Toast('请输入手机号')
          return
        }
        if (!this.ucode) {
          Toast('请输入验证码')
          return
        }
        let params = {
          mobile: this.phone,
          code: this.ucode,
          agency_id: Cookies.get('agency_id') || 2,
          type: 1,
          from_url: 'wap'
        }
        loginByPhone(params).then(res => {
          if (res.errNo == 0){
            console.log(res)
            let result = res.result;
            this.isLogin = true
            if(result.type == 'reg'){
              this.btnText='注册完成，点击进入礼品专区换豪礼'
              this.isNewUser = true
              //获取积分
              this.getRegScore();
            }else {
              this.isNewUser = false
              this.btnText='您是老用户，点击进入礼品专区换豪礼'
            }
          }
        })
      }
    },
    getRegScore() {
      getRegScore({uid:this.invite_uid}).then((res) => {
        if (res.errNo === 0 ){
        }
      })
    },
    getPageInfo() {
      getRepairSign().then((res) => {
        if (res.errNo === 0 ){
          this.pageInfo =res.result
        }
      })
    }
  }
}
</script>
<style>
.signDialog.van-toast{
  color:#EC6B44 !important;
  background: #fff !important;
}
</style>
<style lang="scss" scoped>
.main {
  background-color: #f4f4f4;
  width: 100%;
  height:100%;
}
.content {
  position: relative;
  padding-bottom: 100px;
  width: 100%;
  height: calc(100% - 200px);
  overflow-y: scroll;
  .iv_banner{
    background: #ee2e2e;
    width: 100%;
    height: 400px;
  }
  .div_content{
    position: absolute;
    width: 95%;
    left: 50%;
    transform: translate(-50%,-8%);
    /deep/.el-card__body{
      padding: 32px 50px 16px 50px;
    }
    .box-card1 {
      margin: 20px auto 0;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 96%;
      border-radius: 20px;
      .iv_gift{
        margin-top: 76px;
        width: 100%;
      }
      .div_yhj{
        display: flex;
        justify-content: space-between;
        border-radius: 20px;
        align-items: center;
        width: 618px;
        height: 180px;
        border: 1px solid #bbbbbb;

        .div_left{
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          .div_box{
            .div_1{
              color: rgba(16, 16, 16, 1);
              font-size: 28px;
              text-align: left;
              font-weight: 600;
              font-family: SourceHanSansSC-regular;
            }
            .div_2{
              color: rgba(236, 106, 70, 1);
              font-size: 24px;
              font-weight: 600;
              text-align: left;
              font-family: SourceHanSansSC-regular;
            }
            .div_3{
              margin-top: 22px;
              font-size: 24px;
              text-align: left;
              color: #6C6C6C;
              font-family: SourceHanSansSC-regular;
            }
          }

        }
        .div_right{
          display: flex;
          flex-shrink: 0;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          min-width: 96px;
          padding: 0 12px;
          height: 100%;
          background: #ee2e2e;
          color: white;
          font-size: 28px;
          margin-right: 1px;
          border-top-right-radius: 20px;
          border-bottom-right-radius: 20px;
          font-weight: 600;
        }
      }
      .signBtn {
        margin-top: 26px;
        text-align: center;
        .signBtn1 {
          display: inline-block;
          width: 570px;
          height: 80px;
          font-weight: 600;
          background-color: rgba(236, 107, 68, 1);
          border-radius: 40px;
          font-family: PingFangSC-Medium;
          line-height: 80px;
          font-size: 28px;
          color: #ffffff;
        }
      }
      .code_login {
        width: 620px;
        height: 177px;
        background-color: #ffffff;
        border-radius: 10px;
        border: solid 1px #dcdcdc;
        .phone {
          height: 88px;
          font-size: 32px;
          line-height: 88px;
          display: flex;
          align-items: center;
          flex-shrink: 0;
          justify-content: start;
          border-bottom: solid 1px #dcdcdc;
          width: 100%;
          .diqu {
            width: 130px;
            display: flex;
            flex-shrink: 0;
            justify-content: center;
            align-items: center;
            border-right: 3px solid #dcdcdc;
            overflow: hidden;
            height: 40px;
            span{
              flex-shrink: 0;
              margin-right: 6px;
            }
            input {
              flex-shrink: 0;
              width: 68px;
              font-size: 28px;
              height: 40px;
              line-height: 4%;
            }
            img {
              width: 26px;
              margin-top: 15px;
            }
          }
          .phone_inp {
            width: 50%;
            height: 50px;
            line-height: 50px;
            margin-left: 5%;
          }
        }
        .code_box {
          .code_m {
            width: 70%;
            float: left;
            height: 70px;
            margin-top: 10px;
            border-right: 2px solid #dcdcdc;
            input {
              line-height: 70px;
              margin-left: 20px;
              font-size: 32px;
            }
          }
          .get_code {
            float: left;
            color: #ee2e2e;
            font-size: 26px;
            width: 28%;
            text-align: center;
            line-height: 88px;
          }
        }
      }
    }

  }

}

</style>

import request from '@/utils/ucenterrequest'
import payRequest from '@/utils/payRequest'
import bbsRequest from '@/utils/bbsrequest'
import qs from 'qs'

// 修改头像
export function updateAvatar(params) {
  return request({
    url: '/personal/Myinfo/setAvatar',
    method: 'get',
    params: params
  })
}
// 修改用户信息
export function updateRegistInfo(params) {
  return request({
    url: '/personal/Myinfo/regisInfo',
    method: 'get',
    params: params
  })
}
// 获取用户注册信息
export function getRegisInfo(params) {
  return request({
    url: '/personal/Myinfo/getRegisInfo',
    method: 'get',
    params: params
  })
}
// 获取省市地区
export function getAgreement(params) {
  return payRequest({
    url: 'pay/order/getPayProtocol',
    method: 'get',
    params: params
  })
}
// 获取省市地区
export function getDistrict(params) {
  return payRequest({
    url: '/pay/address/getDistrict',
    method: 'get',
    params: params
  })
}
// 推荐可关注的人
export function followList(params) {
  return request({
    url: '/personal/Task/followList',
    method: 'get',
    params: params
  })
}
// 添加关注
export function addFollow(params) {
  return bbsRequest({
    url: '/user/follow/follow',
    method: 'get',
    params: params
  })
}

// 获取专业
export function getMajorList(params) {
  return request({
    url: '/personal/Myinfo/getMajorList',
    method: 'get',
    params: params
  })
}

// 获取筑龙币记录
export function myInteg(params) {
  return request({
    url: '/personal/Myinfo/myInteg',
    method: 'get',
    params: params
  })
}

// 新手任务列表接口
export function newTaskIndex(params) {
  return request({
    url: '/personal/Task/index',
    method: 'get',
    params: params
  })
}

// 完成关注任务领奖接口
export function followGetScore(params) {
  return request({
    url: '/personal/Task/followGetScore',
    method: 'get',
    params: params
  })
}

// 当天签到
export function signToday(params) {
  return request({
    url: '/api/user/sign',
    method: 'get',
    params: params
  })
}

// 用户协议
export function getPayProtocol(params) {
  return payRequest({
    url: 'pay/order/getPayProtocol',
    method: 'get',
    params: params
  })
}

// 用户协议
export function setCategory(params) {
  return request({
    url: '/ucenter/user/setCategory',
    method: 'get',
    params: params
  })
}
// 微信参数
export function getJSConfig(params) {
  return request({
    url: '/wechat/fission/getJSConfig',
    method: 'get',
    params
  })
}
// 补签接口
export function repaireSign(params) {
  return request({
    url: '/personal/Task/repaireSign',
    method: 'post',
    data: qs.stringify(params)
  })
}
// 邀请页面注册后领积分
export function getRegScore(params) {
  return request({
    url: '/personal/Task/getRegScore',
    method: 'post',
    data: qs.stringify(params)
  })
}
// 获取邀请奖励的积分数，赠卡数,邀请页面头图
export function getRepairSign(params) {
  return request({
    url: '/personal/Task/getRepairSign',
    method: 'post',
    data: qs.stringify(params)
  })
}
// 邀请图
export function getRegImg(params) {
  return request({
    url: '/personal/Task/getRegImg',
    method: 'post',
    data: qs.stringify(params)
  })
}
/**
 * [获取注册信息-新接口-林杰]
 **/
export function getNewRegisInfo() {
  return request({
    url: '/personal/Myinfo/getNewRegisInfo',
    method: 'get'
  })
}
/**
 * [获取注册信息-新接口-林杰]
 **/
export function newRegisInfo(params) {
  return request({
    url: '/personal/Myinfo/newRegisInfo',
    method: 'post',
    contentType: 'application/x-www-from-urlencoded',
    data: qs.stringify(params)
  })
}
// 学历
export function getEduConfig(params) {
  return request({
    url: '/personal/Myinfo/getEduBackList',
    method: 'post',
    data: qs.stringify(params)
  })
}

// 微信文本校验
export function wxchectMessage(params) {
  return bbsRequest({
    url: '/thread/thread/chectMessage',
    method: 'post',
    data: qs.stringify(params)
  })
}

// 发帖
export function postThread(data) {
  return bbsRequest({
    url: 'thread/thread/insertThread',
    method: 'post',
    data
  })
}

// 关注小组
export function attendGroup(data) {
  return bbsRequest({
    url: 'user/user/attendGroup',
    method: 'post',
    data
  })
}

// import request from '@/utils/commonrequest'
import request from '@/utils/bbsrequest.js'
// infohome页面头部企业信息头像接口
export function getagencyInfo(params) {
  return request({
    url: '/api/Agency/getAgencyInfo',
    methods: 'get',
    params
  })
}
export function getAppCommon(params) {
  return request({
    url: '/api/Agency/getAppCommon',
    methods: 'get',
    params
  })
}

// 三方统计
export function getAppCommonStatis(params) {
  return request({
    url: '/api/Agency/getAppCommonStatis',
    methods: 'get',
    params
  })
}

export function getShares(params) {
  return request({
    url: '/thread/thread/getShares',
    method: 'get',
    params
  })
}

import request from '@/utils/ucenterrequest'
import qs from 'qs'

export function signIn(params) { // 账号和密码登录
  return request({
    url: '/user/logon/signIn',
    method: 'post',
    params
  })
}
export function getphoneCode(params) { // 获取验证码
  return request({
    url: '/user/logon/sendMobileCode',
    method: 'get',
    params
  })
}
export function wx_getphoneCode(params) { // 获取验证码
  return request({
    url: '/user/logon/signIn',
    method: 'get',
    params
  })
}
export function updatePassword(params) { // 修改密码
  return request({
    url: '/personal/Myinfo/updatePassword',
    method: 'get',
    params
  })
}
export function changePwd(params) { // 重置
  return request({
    url: '/user/logon/changePwd',
    method: 'get',
    params
  })
}

// 用户名密码登录(新)
export function loginByUserName(params) {
  return request({
    url: '/ucenter/login/loginByUserName',
    method: 'post',
    params
  })
}
// 验证码登录(新)
export function loginByPhone(params) {
  return request({
    url: '/ucenter/login/loginByPhone',
    method: 'post',
    params
  })
}
// 获取短信验证码(新)
export function sendMobileCode(params) {
  return request({
    url: '/ucenter/login/sendMobileCode',
    method: 'post',
    params
  })
}
// 修改密码(新)
export function changePwdNew(params) {
  return request({
    url: '/ucenter/user/changePwd',
    method: 'post',
    params
  })
}
// 重置密码(新)
export function resetPwd(params) {
  return request({
    url: '/ucenter/user/resetPwd',
    method: 'post',
    params
  })
}
// 退出登录(新)
export function logOut(params) {
  return request({
    url: '/ucenter/login/logOut',
    method: 'post',
    params
  })
}
// 手机号绑定(新)
export function bindMobile(params) {
  return request({
    url: '/ucenter/user/bindMobile',
    method: 'post',
    params
  })
}
// 账号是否存在
export function findAccount(params) {
  return request({
    url: '/user/pwd/findAccount',
    method: 'get',
    params
  })
}
// 发送手机验证码
export function sendMobile(params) {
  return request({
    url: '/user/pwd/sendMobile',
    method: 'get',
    params
  })
}
// 发送邮箱验证码
export function sendEmail(params) {
  return request({
    url: '/user/pwd/sendEmail',
    method: 'get',
    params
  })
}
// 验证手机验证码
export function vertifyMobile(params) {
  return request({
    url: '/user/pwd/vertifyMobile',
    method: 'get',
    params
  })
}
// 验证邮箱验证码
export function vertifyEmail(params) {
  return request({
    url: '/user/pwd/vertifyEmail',
    method: 'get',
    params
  })
}
// 修改密码
export function updatePwd(params) {
  return request({
    url: '/user/pwd/updatePwd',
    method: 'get',
    params
  })
}
// 获取客服链接
export function pageInit(params) {
  return request({
    url: '/user/pwd/pageInit',
    method: 'get',
    params
  })
}

/**
 * [获取我的信息]
 **/
export function getLoginUserInfo(params) {
  return request({
    url: 'personal/myinfo/getUserinfo',
    params: params,
    method: 'get'
  })
}

// 获取小程序跳转链接
export function getMiniUrlLink(params) {
  return request({
    url: 'ucenter/miniprogram/getMiniUrlLink',
    params: params,
    method: 'post'
  })
}

// 获取公众号信息
export function getConfig(params) {
  return request({
    url: '/wechat/wechat/getConfig',
    method: 'get',
    params
  })
}

//  新：获取是否绑定微信 需要登录状态下查询
export function ischeckBindByuid(params) {
  return request({
    url: 'wechat/wechat/ischeckBindByuid',
    params: params,
    method: 'post'
  })
}

// 是否绑定网站账号  不需要登录状态 传 openid  unionid 查询
export function ischeckBind(params) {
  return request({
    url: 'wechat/wechat/ischeckBind',
    method: 'POST',
    data: qs.stringify(params)
  })
}

// 绑定账号
export function InsetBind(params) {
  return request({
    url: '/wechat/wechat/InsetBind',
    method: 'get',
    params
  })
}
// 微信网页授权 code 交换unionid
export function getSnsUserInfo(params) {
  return request({
    url: '/wechat/wechat/getSnsUserInfo',
    method: 'get',
    params
  })
}
